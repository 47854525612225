import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getStudentFeeDetails,
  getFeeDetailUtilityWise,
  updateStudentFees,
} from "../config/api";

const PaymentDetails = () => {
  const location = useLocation();
  const { studentId, studentName } = location.state || {};
  // Initialize the state as an empty object
  const [formData, setFormData] = useState({});

  const [paymentMode, setPaymentMode] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [remark, setRemark] = useState("");
  const [feeDetail, setFeeDetail] = useState({});
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [selectedNames, setSelectedNames] = useState({});
  const [render, setRender] = useState(true);

  useEffect(() => {
    const fetchFeeDetails = async (studentId) => {
      try {
        const response1 = await getStudentFeeDetails(studentId);
        const response2 = await getFeeDetailUtilityWise(studentId);

        if (response1.data && response2.data) {
          setFeeDetail(response1.data);
          console.log(response1.data);
          console.log(response2.data);

          setData(response2.data.utilityData);
        }
      } catch (error) {
        console.error("Unexpected response format:", error);
        setError("Unexpected response format");
      }
    };

    if (studentId) {
      fetchFeeDetails(studentId);
    }
  }, [studentId, , render]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: parseInt(value),
    });
  };

  const handlePayment = async () => {
    if (Object.entries(formData).length === 0) {
      return alert("Please fill required fields");
    }

    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => !isNaN(value))
    );

    const paymentObj = {
      student_id: studentId,
      utility: filteredFormData,
      payment_mode: paymentMode,
      ref_no: referenceNo,
      remark: remark,
    };
    console.log(paymentObj);

    try {
      await updateStudentFees(paymentObj);
      console.log("Payment Successful:", paymentObj);
    } catch (error) {
      console.error("Payment Error:", error);
    }

    alert("Payment Successful");
    setRender(!render);
    setFormData({});
    setSelectedNames({});
  };

  const handleCheckboxChange = (name) => {
    setSelectedNames((prevSelectedNames) => ({
      ...prevSelectedNames,
      [name]: !prevSelectedNames[name],
    }));
  };

  if (!studentId || !studentName) {
    return <div className="text-center text-lg font-semibold">Loading...</div>;
  }

  return (
    <div className="container mx-auto ">
      <div className="bg-white max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">
          Payment Details
        </h1>
        <div className="flex">
          <div>
            <p className="font-medium text-gray-700">
              <strong>Student ID:</strong> {studentId}
            </p>
            <p className="font-medium text-gray-700">
              <strong>Student Name:</strong> {studentName}
            </p>

            <div className="my-16 container">
              <div className="my-12">
                <p className="font-medium text-gray-700">
                  <strong>Total amount:</strong> Rs {feeDetail.totalFee}
                </p>
                <p className=" font-medium text-gray-700">
                  <strong>Total Due:</strong> Rs {feeDetail.pendingFee}
                </p>
              </div>

              <div className="font-medium text-gray-700 mt-4">
                <strong>Payment Mode:</strong>
                <input
                  type="text"
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  className="border border-gray-300 rounded px-2 py-1 ml-2"
                />
              </div>

              <div className="font-medium text-gray-700 mt-4">
                <strong>Reference No:</strong>
                <input
                  type="text"
                  value={referenceNo}
                  onChange={(e) => setReferenceNo(e.target.value)}
                  className="border border-gray-300 rounded px-2 py-1 ml-2"
                />
              </div>
              <div className="font-medium text-gray-700 mt-4">
                <strong>Remark:</strong>
                <input
                  type="text"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  className="border border-gray-300 rounded px-2 py-1 ml-2"
                />
              </div>
            </div>
            <div className="flex">
              <table className="w-full border-collapse border border-gray-300 mt-4">
                <thead>
                  <tr>
                    <th className="p-2"> </th>
                    {data.map((entry, index) => (
                      <th key={index} className="border border-gray-300 p-2">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={!!selectedNames[entry.UtilityId]}
                            onChange={() =>
                              handleCheckboxChange(entry.UtilityId)
                            }
                            className="mr-2"
                          />
                          {entry.utility.name}
                        </label>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-300 p-2 font-medium text-gray-700">
                      Amount
                    </td>
                    {data.map((entry, index) => (
                      <td key={index} className="border border-gray-300 p-2">
                        <input
                          type="text"
                          value={entry.utility.amount}
                          disabled
                          // onChange={handleInputChange(index, "amount")}
                          className="border border-gray-300 rounded px-2 py-1"
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2 font-medium text-gray-700">
                      Due
                    </td>
                    {data.map((entry, index) => (
                      <td key={index} className="border border-gray-300 p-2">
                        <input
                          type="text"
                          value={entry.remainingAmount}
                          disabled
                          // onChange={handleInputChange(index, "due")}
                          className="border border-gray-300 rounded px-2 py-1"
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2 font-medium text-gray-700">
                      Pay
                    </td>
                    {/* // In the render method: */}
                    {data.map((entry, index) => (
                      <td key={index} className="border border-gray-300 p-2">
                        <input
                          type="number"
                          placeholder="0"
                          name={entry.UtilityId}
                          value={formData[entry.UtilityId] || ""} // Handle cases where entry.pay might be undefined
                          onChange={handleChange}
                          disabled={!selectedNames[entry.UtilityId]} // Only editable if checkbox is checked
                          className="border border-gray-300 rounded px-2 py-1"
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex my-6">
              <button
                className={`p-2 w-60 rounded-md font-semibold ${
                  paymentMode.length && Object.values(formData).length
                    ? "bg-purple-600 text-white"
                    : "bg-gray-400 text-gray-200"
                }`}
                onClick={handlePayment}
                disabled={!paymentMode.length}
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
